export default [
  { value: '26-2401', label: 'Abfackelungsanlage' },
  { value: '34-5852', label: 'Abfüllanlage (Nahrung)' },
  { value: '30-5221', label: 'Abkanntpresse' },
  { value: '40-7015', label: 'Abmesseinrichtung' },
  { value: '46-4101', label: 'Abraumfördergerät' },
  { value: '26-2305', label: 'Abscheideanlage (Wasser)' },
  { value: '42-7098', label: 'Absetzkipper' },
  { value: '42-8098', label: 'Absetzkipper in Steinbruch/Schrott' },
  { value: '26-2601', label: 'Absorptions-Kälteanlagen' },
  { value: '40-7015', label: 'Abzugsgeräte' },
  { value: '25-2103', label: 'Akkumulatorenbatterien' },
  { value: '39-6319', label: 'Anbaugeräte für Landwirtschaft' },
  { value: '35-6659', label: 'Anzeigetafeln' },
  { value: '34-5852', label: 'Apparate unter Dampfdruck' },
  { value: '40-6320', label: 'Arbeitsgeräte in der Forstwirtschaft' },
  { value: '29-4709', label: 'Arbeitsmaschinen für Reinigung/Wäscherei' },
  { value: '27-2512', label: 'Armaturen für Gas' },
  { value: '26-3204', label: 'Armaturen für Heizung/Hauswasser/Abw.' },
  { value: '26-2604', label: 'Armaturen für Kälteerzeugung und Vers.' },
  { value: '25-2303', label: 'Armaturen für Wasser/Fernwärme' },
  { value: '40-7015', label: 'Aufgeber' },
  { value: '42-7106', label: 'Aufreißer' },
  { value: '41-7046', label: 'Autobetonpumpen' },
  { value: '41-7064', label: 'Autohebebühnen' },
  { value: '34-5852', label: 'Autoklaven für Nahrung' },
  { value: '41-7034', label: 'Autokrane' },
  { value: '36-8901', label: 'Backenbrecher' },
  { value: '42-7106', label: 'Bagger' },
  { value: '42-7107', label: 'Bagger in Steinbruch/Schrott' },
  { value: '41-7041', label: 'Becherwerke transportabel' },
  { value: '26-2511', label: 'Behälter für Gas' },
  { value: '36-6959', label: 'Behälter für Lager/Umschlaganlagen' },
  { value: '34-6209', label: 'Behälter für Nahrung' },
  { value: '35-6711', label: 'Behälter für Tankstellen' },
  { value: '26-2721', label: 'Behälter in Kraftanlagen' },
  { value: '40-7018', label: 'Behälter transportabel' },
  { value: '46-4000', label: 'Bergbau, Torfgewinng.- u. aufbereitung' },
  { value: '46-5520', label: 'Beschichtungspressen' },
  { value: '28-3751', label: 'Beschickungsautomaten' },
  { value: '43-7511', label: 'Betondeckenfertiger (Straßenbau)' },
  { value: '41-7045', label: 'Betonpumpen einschl. Rohrleitungen' },
  { value: '30-5221', label: 'Biegemaschine (Metall)' },
  { value: '45-1536', label: 'Biogasanlagen mit Gasottoverfahren' },
  { value: '45-1535', label: 'Biogasanlagen mit Zündstrahlverfahren' },
  { value: '45-1539', label: 'Biogasanlagen o. Verbrennungsmotoren' },
  { value: '31-5260', label: 'Blechbearbeitungs- u. Drahtziehmasch.' },
  { value: '31-5264', label: 'Blechbedruckmaschinen' },
  { value: '30-5201', label: 'Blechwalzwerk' },
  { value: '25-1533', label: 'Blockheizkraftwerke mit Kolbenmaschin.' },
  { value: '41-7033', label: 'Bockkrane' },
  { value: '43-7511', label: 'Bodenvermörtelungsgeräte' },
  { value: '33-5653', label: 'Bogenoffsetmaschinen' },
  { value: '33-5653', label: 'Bogenrotationsmaschinen' },
  { value: '35-6509', label: 'Bohnermaschinen' },
  { value: '43-7401', label: 'Bohranlagen für Brunnenbau, Erdbohrg.' },
  { value: '39-4202', label: 'Bohranlagen für Erdöl und Erdgas' },
  { value: '47-4203', label: 'Bohrgeräte (schwimmend)' },
  { value: '47-4204', label: 'Bohrinseln' },
  { value: '42-7100', label: 'im Baugewerbe' },
  { value: '39-6316', label: 'Ballenpressen für Landwirtschaft' },
  { value: '29-4601', label: 'Ballenpressen für Textil' },
  { value: '46-4101', label: 'Bandabsetzer' },
  { value: '41-7044', label: 'Bandfördereinrichtungen' },
  { value: '28-3704', label: 'Bandfördereinrichtungen für Transporteinr.' },
  { value: '41-7041', label: 'Bauaufzüge' },
  { value: '44-7851', label: 'Baubaracken' },
  { value: '44-7851', label: 'Baubuden' },
  { value: '44-7851', label: 'Baubüros' },
  { value: '40-6320', label: 'Baumsägen fahrbar' },
  { value: '32-5501', label: 'Baumsägen stationär' },
  { value: '36-8900', label: 'Baustoff, Steinbruch, Glas' },
  { value: '32-5558', label: 'Bearbeitungszentren für Holz' },
  { value: '31-5301', label: 'Bearbeitungszentren für spanab. Form.' },
  { value: '28-3704', label: 'Becherwerke für Transporteinrichtungen' },
  { value: '31-5301', label: 'Bohrmaschinen für spanabh. Formung' },
  { value: '47-4203', label: 'Bohrturm (schwimmend)' },
  { value: '48-7651', label: 'Bohrwagen im Tunnelbau' },
  { value: '31-5261', label: 'Bördelmaschinen' },
  { value: '34-5901', label: 'Brecher für Nahrung' },
  { value: '29-5001', label: 'Brecheranlagen für Metallaufbereitung' },
  { value: '46-4121', label: 'Brikettpressen' },
  { value: '43-7400', label: 'Brunnenbau, Erdbohrung., Wasserhaltg..' },
  { value: '29-4701', label: 'Bügelmaschinen' },
  { value: '29-4701', label: 'Bügelpressen' },
  { value: '28-4500', label: 'Chemie allgemein' },
  { value: '33-5661', label: 'Chromacomsysteme' },
  { value: '33-5657', label: 'Chromographen' },
  { value: '46-5523', label: 'Conti-Roll-Pressen' },
  { value: '34-5901', label: 'Cutter für Nahrung' },
  { value: '24-1012', label: 'Dampfautomaten' },
  { value: '24-1010', label: 'Dampferzeugungsanlagen' },
  { value: '24-1012', label: 'Dampfgeneratoren' },
  { value: '41-7075', label: 'Dampflokomotiven' },
  { value: '24-1302', label: 'Dampfmotoren' },
  { value: '34-5852', label: 'Dampftunnel für Nahrung' },
  { value: '29-4539', label: 'Dentalfräsmaschine' },
  { value: '41-7033', label: 'Derrickkrane' },
  { value: '26-2305', label: 'Desinfektionsanlagen für Trinkwasser' },
  { value: '34-5852', label: 'Destillationsanlagen' },
  { value: '41-7072', label: 'Diesellokomotiven' },
  { value: '24-1502', label: 'Dieselmotore' },
  { value: '43-7321', label: 'Dieselwalzen' },
  { value: '33-5656', label: 'Digitaldruckmaschinen' },
  { value: '36-6911', label: 'Drahtseilbahnen' },
  { value: '47-3741', label: 'Drahtseilbahnen in der Industrie' },
  { value: '30-5201', label: 'Drahtwalzwerk' },
  { value: '31-5262', label: 'Drahtziehmaschinen' },
  { value: '31-5302', label: 'Drehbänke für spanabhebende Formung' },
  { value: '25-2203', label: 'Drosselspulen' },
  { value: '43-7601', label: 'Druckluftbehälter' },
  { value: '43-7600', label: 'Druckluftgeräte' },
  { value: '43-7601', label: 'Drucklufthämmer' },
  { value: '43-7601', label: 'Druckluftschleusen' },
  { value: '33-5652', label: 'Druckpressen' },
  { value: '42-7098', label: 'Dumper' },
  { value: '46-4102', label: 'Durchlaufbrecher für Bergbau' },
  { value: '29-5001', label: 'Durchlaufbrecher für Metallaufbereitung' },
  { value: '24-1012', label: 'Durchlauferhitzer' },
  { value: '39-6319', label: 'Eggen' },
  { value: '42-7106', label: 'Eimerkettenbagger' },
  { value: '47-8701', label: 'Eimerkettenschwimmbagger' },
  { value: '46-5520', label: 'Einetagenspanplattenpressen' },
  { value: '25-2302', label: 'Eintauchpumpen' },
  { value: '35-6611', label: 'Eisaufbereitung für Kunsteisbahnen' },
  { value: '41-7035', label: 'Eisenbahnkrane' },
  { value: '25-2000', label: 'Elektrische Versorgungsanlagen' },
  { value: '41-7062', label: 'Elektrokarren' },
  { value: '41-7071', label: 'Elektrolokomotiven' },
  { value: '25-1801', label: 'Elektromotore' },
  { value: '41-7062', label: 'Elektroschlepper' },
  { value: '25-1801', label: 'Elektrozüge' },
  { value: '25-2105', label: 'Erdkabel' },
  { value: '48-7656', label: 'Erdraketen' },
  { value: '25-2203', label: 'Erdschlussspulen' },
  { value: '35-6509', label: 'Exhaustoren' },
  { value: '47-1302', label: 'Expansionsmotore, schwimmend' },
  { value: '34-5852', label: 'Extraktionsanlagen' },
  { value: '29-4531', label: 'Extruder' },
  { value: '29-4701', label: 'Färbanlage für Wäsche' },
  { value: '27-3211', label: 'Fahrstuhl' },
  { value: '28-3731', label: 'Fahrzeugwaage' },
  { value: '33-5659', label: 'Falzmaschinen für graphisches Gewerbe' },
  { value: '39-6319', label: 'Feldspritze' },
  { value: '38-3301', label: 'Feuerspritzen' },
  { value: '35-6800', label: 'Film, Funk, Kino, Theater' },
  { value: '33-5663', label: 'Filmentwicklungsgeräte' },
  { value: '46-4001', label: 'Fördermaschinen für Bergbau' },
  { value: '41-7044', label: 'Förderschnecken' },
  { value: '30-5150', label: 'Formmaschinen für Gießereien' },
  { value: '37-8951', label: 'Formmaschinen für Baust./Steinbr./Glas' },
  { value: '33-5662', label: 'Fotoanlagen' },
  { value: '34-6209', label: 'Flaschenabfüll-/-reinigungsanlage' },
  { value: '47-1541', label: 'Flugmotore' },
  { value: '47-1541', label: 'Flugtriebwerke' },
  { value: '47-2107', label: 'Flusskabel' },
  { value: '32-5559', label: 'Fräsmaschinen für Holz' },
  { value: '31-5301', label: 'Fräsmaschinen für spanabh. Formung' },
  { value: '46-2104', label: 'Freileitungen einschl. Masten' },
  { value: '34-5901', label: 'Fruchtpressen' },
  { value: '28-3500', label: 'Fundamente' },
  { value: '31-5304', label: 'Funkenerosionsmaschinen' },
  { value: '44-7853', label: 'Funkgeräte' },
  { value: '32-5552', label: 'Furniermessermaschinen' },
  { value: '34-6329', label: 'Futterdosieranlagen' },
  { value: '39-6317', label: 'Futtermittelmischwagen' },
  { value: '41-7062', label: 'Gabelstapler mit elektrischen Antrieb' },
  { value: '41-7061', label: 'Gabelstapler mit Verbrennungsmotor' },
  { value: '32-5310', label: 'Galvanik' },
  { value: '32-5312', label: 'Galvanik elektr. Beheizungseinrichtg.' },
  { value: '32-5311', label: 'Galvanik mechanische Einrichtungen' },
  { value: '26-2400', label: 'Gaserzeug.- und vers., Drucklufterzeug.' },
  { value: '26-2401', label: 'Gasreinigungsanlagen' },
  { value: '31-5281', label: 'Gasschmelzschweißanlagen' },
  { value: '26-2401', label: 'Gasspaltanlagen' },
  { value: '32-5501', label: 'Gatter' },
  { value: '44-7851', label: 'Gerätewagen' },
  { value: '43-7711', label: 'Gerüste (maschinell)' },
  { value: '43-7700', label: 'Gerüste , Schalungen' },
  { value: '43-7601', label: 'Gesteinsbohrmaschinen' },
  { value: '47-1601', label: 'Getriebe in Luft- und Raumfahrt' },
  { value: '30-5150', label: 'Gießereien' },
  { value: '30-5171', label: 'Gießmaschinen für Metall' },
  { value: '25-2203', label: 'Gleichrichter' },
  { value: '43-7551', label: 'Gleisbettreinigungsmaschinen' },
  { value: '43-7550', label: 'Gleisoberbau' },
  { value: '43-7551', label: 'Gleisrichtmaschinen' },
  { value: '43-7551', label: 'Gleisstopfmaschinen' },
  { value: '28-3731', label: 'Gleiswaagen' },
  { value: '38-3401', label: 'Golfplatzfahrzeuge' },
  { value: '38-3401', label: 'Golfplatzpflegegeräte' },
  { value: '36-6911', label: 'Gondelbahnen' },
  { value: '42-7106', label: 'Grabenziehmaschinen' },
  { value: '42-7106', label: 'Grader' },
  { value: '28-4521', label: 'Granulieranlagen' },
  { value: '33-5661', label: 'Graphische Gestaltungssysteme' },
  { value: '33-5650', label: 'Graphisches Gewerbe' },
  { value: '33-5657', label: 'Graviereinrichtungen' },
  { value: '47-8701', label: 'Greifer für Kiesgewinnung (schwimmend)' },
  { value: '39-6313', label: 'Grubber' },
  { value: '34-5903', label: 'Grünfuttertrocknungsanlagen' },
  { value: '39-6314', label: 'Gülleausbringfahrzeug, nichtselbstfahrend' },
  { value: '39-6313', label: 'Gülleausbringfahrzeug, selbstfahrend' },
  { value: '43-7321', label: 'Gummiradwalzen' },
  { value: '39-6319', label: 'Gurkenflieger' },
  { value: '43-7511', label: 'Gussasphaltdeckenfertiger' },
  { value: '32-5552', label: 'Hackrotoren für Holz' },
  { value: '39-6315', label: 'Häcksler' },
  { value: '28-3601', label: 'Hallenlaufkrane' },
  { value: '46-4102', label: 'Hammerbrecher für Bergbau' },
  { value: '29-5001', label: 'Hammerbrecher für Metallaufbereitung' },
  { value: '29-5001', label: 'Hammermühlen (Schrott)' },
  { value: '27-3200', label: 'Haustechnische Anlagen' },
  { value: '41-7051', label: 'Hebeböcke' },
  { value: '28-3731', label: 'Hebebühnen stationär' },
  { value: '41-7030', label: 'Hebezeuge und Transportgeräte' },
  { value: '33-5659', label: 'Hefter' },
  { value: '35-6509', label: 'Heißmangel' },
  { value: '24-1000', label: 'Heizungs- und Warmwasseranlagen' },
  { value: '24-1001', label: 'Heizungskessel' },
  { value: '39-6319', label: 'Heuwender' },
  { value: '31-5303', label: 'Hobelbänke für spanabhebende Formung' },
  { value: '32-5559', label: 'Hobelmaschinen für Holz' },
  { value: '25-2001', label: 'Hoch- und Niederspannung' },
  { value: '43-7711', label: 'Hochbaugerüste' },
  { value: '43-7601', label: 'Hochdruckreiniger' },
  { value: '46-5005', label: 'Hochofenanlagen' },
  { value: '32-5500', label: 'Holz' },
  { value: '40-6320', label: 'Holzerntemaschinen' },
  { value: '40-6320', label: 'Holzrückezüge' },
  { value: '34-5852', label: 'Homogenisieranlagen' },
  { value: '34-5901', label: 'Hopfenpressen' },
  { value: '38-3302', label: 'Hubarbeitsbühnen (Anhänger)' },
  { value: '38-3301', label: 'Hubarbeitsbühnen (selbstfahrend)' },
  { value: '47-1541', label: 'Hubschrauberantriebe' },
  { value: '41-7062', label: 'Hubstapler mit elektrischen Antrieb' },
  { value: '41-7061', label: 'Hubstapler mit Verbrennungsmotor' },
  { value: '38-3301', label: 'Hubsteiger' },
  { value: '30-5194', label: 'Hydraulikpressen' },
  { value: '28-3751', label: 'Industrieroboter' },
  { value: '41-7033', label: 'Kabelkrane' },
  { value: '30-5194', label: 'Kabelummantelungspressen' },
  { value: '35-6509', label: 'Kaffeemaschinen' },
  { value: '28-4522', label: 'Kalander' },
  { value: '26-2601', label: 'Kältemaschinen für Erzeugung/Versorg.' },
  { value: '35-6611', label: 'Kältemaschinen für Kunsteisbahnen' },
  { value: '26-2600', label: 'Kälterzeugungs- und Versorgungsanl.' },
  { value: '38-3401', label: 'Kanalspülgeräte' },
  { value: '48-7657', label: 'Kanaluntersuchungs- u. Sanierungsger.' },
  { value: '29-4601', label: 'Karden' },
  { value: '39-6312', label: 'Kartoffelvollernter' },
  { value: '32-5601', label: 'Kartonmaschinen' },
  { value: '33-5621', label: 'Kaschiermaschinen' },
  { value: '35-6659', label: 'Kegelbahnen' },
  { value: '38-3401', label: 'Kehrmaschinen' },
  { value: '34-5901', label: 'Kelterpressen' },
  { value: '28-3704', label: 'Kettenfördereinrichtg. f. Transporteinr.' },
  { value: '40-7002', label: 'Kieswaschmaschinen' },
  { value: '28-3731', label: 'Kippeinrichtungen' },
  { value: '26-2391', label: 'Kläranlagen mit Gasmotoren' },
  { value: '26-2390', label: 'Kläranlagen ohne Gasmotoren' },
  { value: '48-7016', label: 'Klärschlammtrocknungsanlagen, mobil' },
  { value: '40-7015', label: 'Klassierungseinrichtungen' },
  { value: '41-7041', label: 'Kleinstschrapperanlagen' },
  { value: '27-3205', label: 'Klimaanlagen' },
  { value: '33-5657', label: 'Klischographen' },
  { value: '34-5901', label: 'Kneter für Nahrung' },
  { value: '33-5654', label: 'Kniehebelpressen für graph. Gewerbe' },
  { value: '30-5194', label: 'Kniehebelpressen für Metallverformung' },
  { value: '34-5852', label: 'Kochkessel für Nahrung' },
  { value: '46-4102', label: 'Kohlenstaubmühlen für Bergbau' },
  { value: '29-5002', label: 'Kohlenstaubmühlen für Metallaufbereitg.' },
  { value: '37-8931', label: 'Kollergänge für Baustoff/Steinbruch/Glas' },
  { value: '34-5901', label: 'Kollergänge für Nahrung' },
  { value: '25-2103', label: 'Kompensationsanlagen' },
  { value: '38-3228', label: 'Kompostieranlagen fahrbar' },
  { value: '27-3227', label: 'Kompostieranlagen stationär' },
  { value: '26-2502', label: 'Kompressoren' },
  { value: '43-7601', label: 'Kompressoren einschl. Antrieb' },
  { value: '25-2103', label: 'Kondensatoren' },
  { value: '26-2604', label: 'Kondensatoren für Kälteerz.- und Vers..' },
  { value: '25-1803', label: 'Kontaktumformer' },
  { value: '46-5005', label: 'Konverter' },
  { value: '28-3601', label: 'Krane ortsfest' },
  { value: '34-6500', label: 'Krankenh./Sanator./Betr.Küch./Haus/Hotel' },
  { value: '36-8901', label: 'Kreiselbrecher für Steine' },
  { value: '29-4601', label: 'Krempelsätze' },
  { value: '35-6509', label: 'Kücheneinrichtungen' },
  { value: '26-2721', label: 'Kühltürme' },
  { value: '35-6611', label: 'Kunsteisbahnen' },
  { value: '28-4520', label: 'Kunststoff, Gummi, Linoleum' },
  { value: '29-4536', label: 'Kunststoff-Fensterrahmenfertigungsanl.' },
  { value: '28-4523', label: 'Kunststoff-Schweißmaschinen' },
  { value: '30-5194', label: 'Kurbelexcenterpressen' },
  { value: '46-5520', label: 'Kurztaktpressen' },
  { value: '44-7853', label: 'Laborgeräte' },
  { value: '44-7851', label: 'Labors' },
  { value: '32-5359', label: 'Lackieranlagen' },
  { value: '42-7106', label: 'Ladegeräte auf Raupen und luftbereift' },
  { value: '25-2203', label: 'Ladegeräte elektrische Versorgung' },
  { value: '42-7107', label: 'Ladegeräte in Steinbruch/Schrott' },
  { value: '39-6317', label: 'Ladewägen für Landwirtschaft' },
  { value: '36-6950', label: 'Lager- und Umschlaganlagen' },
  { value: '39-6300', label: 'Land- und Forstwirtschaft' },
  { value: '34-6329', label: 'Landwirtschaft stationär' },
  { value: '40-6401', label: 'Langlaufspurgeräte' },
  { value: '31-5286', label: 'Laserschneideanlagen' },
  { value: '27-3211', label: 'Lastenaufzüge Haustechnik' },
  { value: '28-3601', label: 'Lastenaufzüge Transporteinrichtungen' },
  { value: '41-7033', label: 'Laufkrane' },
  { value: '29-4550', label: 'Leder' },
  { value: '43-7711', label: 'Lehrgerüste für Brückenbau' },
  { value: '38-3301', label: 'Leitern' },
  { value: '31-5282', label: 'Lichtbogenschneidemaschinen' },
  { value: '31-5282', label: 'Lichtbogenschweißmaschinen' },
  { value: '33-5662', label: 'Lichtsatzanlagen' },
  { value: '42-7098', label: 'LKW-Muldenkipper' },
  { value: '42-8098', label: 'LKW-Muldenkipper in Steinbruch/Schrott' },
  { value: '33-5659', label: 'Locher' },
  { value: '47-8701', label: 'Löffelbagger (schwimmend)' },
  { value: '24-1302', label: 'Lokomobile' },
  { value: '41-7071', label: 'Lokomotiven' },
  { value: '38-3907', label: 'Loren' },
  { value: '47-1540', label: 'Luft- und Raumfahrt' },
  { value: '27-3205', label: 'lufttechnische Anlagen' },
  { value: '44-7851', label: 'Magazine' },
  { value: '39-6311', label: 'Mähdrescher' },
  { value: '39-6311', label: 'Mähmaschinen' },
  { value: '46-4002', label: 'Maschinen unter Tage' },
  { value: '46-5523', label: 'Mehretagenspanplattenpressen' },
  { value: '24-1502', label: 'Mehrstoffmotore' },
  { value: '34-6329', label: 'Melkanlagen' },
  { value: '25-2001', label: 'Messanlagen' },
  { value: '27-3019', label: 'Messgeräte' },
  { value: '25-2203', label: 'Messwandler' },
  { value: '29-5000', label: 'Metallaufbereitung' },
  { value: '31-5282', label: 'Metallaufspritzmaschinen' },
  { value: '30-5171', label: 'Metallgussanlagen' },
  { value: '42-7108', label: 'Minibagger bis EUR 25.000' },
  { value: '42-7108', label: 'Miniladegeräte bis EUR 25.000' },
  { value: '42-7108', label: 'Miniraupen bis EUR 25.000' },
  { value: '42-7108', label: 'Minischürfgeräte bis EUR 25.000' },
  { value: '40-7000', label: 'Mischanlagen' },
  { value: '40-7011', label: 'Mischanlagen transportabel' },
  { value: '30-5151', label: 'Mischanlagen für Gießereien' },
  { value: '28-4514', label: 'Mischer für Chemie' },
  { value: '40-7011', label: 'Mischtürme' },
  { value: '35-6509', label: 'Mixmaschinen' },
  { value: '41-7034', label: 'Mobilkrane' },
  { value: '25-1803', label: 'Motorgeneratoren' },
  { value: '40-6401', label: 'Motorschlitten' },
  { value: '42-7106', label: 'Motorschürfwagen' },
  { value: '42-7106', label: 'Motorstraßenhobel' },
  { value: '40-7001', label: 'Mühlen transportabel' },
  { value: '30-5151', label: 'Mühlen für Gießereien' },
  { value: '34-5901', label: 'Mühlen für Nahrung' },
  { value: '36-8901', label: 'Mühlen für Steine' },
  { value: '42-7098', label: 'Muldenkipper' },
  { value: '42-8098', label: 'Muldenkipper in Steinbruch/Schrott' },
  { value: '27-3220', label: 'Müllbehandlungsanl., Kompostieranl.' },
  { value: '27-3222', label: 'Müllzerkleinerungsanlagen' },
  { value: '34-5850', label: 'Nahrungs-, Genuss- u.Futtermittelindust.' },
  { value: '31-5261', label: 'Nietanlagen' },
  { value: '31-5261', label: 'Nietmaschinen' },
  { value: '24-1502', label: 'Notstromaggregate mit Dieselantrieb' },
  { value: '24-1501', label: 'Notstromaggregate mit Ottoantrieb' },
  { value: '37-8921', label: 'Öfen für Baustoff/Steinbruch/Glas' },
  { value: '31-5231', label: 'Öfen für Metallwärmebehandlung' },
  { value: '34-5903', label: 'Öfen für Nahrung' },
  { value: '29-4601', label: 'Öffnerzüge' },
  { value: '33-5653', label: 'Offsetmaschinen' },
  { value: '33-5653', label: 'Offsetrotationsmaschinen' },
  { value: '24-1003', label: 'Ölerhitzungsanlagen' },
  { value: '24-1502', label: 'Ölmotore' },
  { value: '24-1001', label: 'Öltanks für Heizungs- und Warmwasser' },
  { value: '24-1501', label: 'Ottomotoren' },
  { value: '32-5600', label: 'Papier-, Pappen-, Zellulose- und Zellst.' },
  { value: '33-5629', label: 'Papierballenpressen' },
  { value: '32-5601', label: 'Papiermaschinen' },
  { value: '32-5620', label: 'Papierverarbeitung' },
  { value: '27-3211', label: 'Paternoster' },
  { value: '27-3211', label: 'Personenaufzüge' },
  { value: '42-7301', label: 'Pfahlzieher' },
  { value: '39-6319', label: 'Pflüge' },
  { value: '25-1803', label: 'Phasenschieber' },
  { value: '40-6401', label: 'Pistenraupen' },
  { value: '40-6401', label: 'Pistenwalzen' },
  { value: '42-7106', label: 'Planierraupen' },
  { value: '29-4531', label: 'Plattenpressen' },
  { value: '29-4701', label: 'Plättmaschinen für Wäsche' },
  { value: '41-7033', label: 'Portalkrane' },
  { value: '33-5654', label: 'Prägepressen für graphisches Gewerbe' },
  { value: '46-4102', label: 'Prallspalter für Bergbau' },
  { value: '29-5002', label: 'Prallspalter für Metallaufbereitung' },
  { value: '32-5521', label: 'Pressen für Holz' },
  { value: '29-4551', label: 'Pressen für Leder' },
  { value: '30-5194', label: 'Pressen für Metall' },
  { value: '27-3222', label: 'Pressen für Müll' },
  { value: '34-5901', label: 'Pressen für Nahrung' },
  { value: '41-7051', label: 'Presspumpen' },
  { value: '46-5520', label: 'Profilrahmenpressen' },
  { value: '30-5201', label: 'Profilwalzwerk' },
  { value: '27-3019', label: 'Prüfgeräte' },
  { value: '32-5359', label: 'Pulverbeschichtung' },
  { value: '27-3204', label: 'Pumpen für Heizung/Hauswasser/Abwass.' },
  { value: '25-2301', label: 'Pumpen für Wasser/Fernwärme' },
  { value: '31-5284', label: 'Punktschweißmaschinen' },
  { value: '42-7106', label: 'Radbagger' },
  { value: '42-7106', label: 'Radlader' },
  { value: '42-7301', label: 'Rammen' },
  { value: '42-7300', label: 'Rammen, Pfahlzieher u. Bodenverdichter' },
  { value: '38-3401', label: 'Rasenmäher' },
  { value: '42-7107', label: 'Raupen in Steinbruch/Schrott' },
  { value: '42-7106', label: 'Raupenbagger' },
  { value: '41-7035', label: 'Raupenkrane' },
  { value: '42-7106', label: 'Raupenlader' },
  { value: '25-2001', label: 'Regelanlagen' },
  { value: '29-4701', label: 'Reinigungsanlagen für Wäsche' },
  { value: '29-4700', label: 'Reinigungsanst./Wäschereien/Waschs.' },
  { value: '29-4601', label: 'Reißwölfe' },
  { value: '34-5852', label: 'Rektifizieranlagen für Nahrung' },
  { value: '33-5657', label: 'Reproduktionsgeräte' },
  { value: '26-2512', label: 'Rohrleitungen für Gas' },
  { value: '27-3204', label: 'Rohrleitungen f. Heiz./Hausw./Abwass.' },
  { value: '26-2604', label: 'Rohrleitungen für Kälteerzeug.- u. Vers.' },
  { value: '25-2303', label: 'Rohrleitungen für Wasser/Fernwärme' },
  { value: '30-5201', label: 'Rohrwalzwerk' },
  { value: '38-3907', label: 'Rollendes Material' },
  { value: '28-3731', label: 'Rollenförderer' },
  { value: '33-5655', label: 'Rollenoffsetmaschinen' },
  { value: '33-5651', label: 'Rollenrotationsmaschinen' },
  { value: '27-3213', label: 'Rolltore' },
  { value: '27-3211', label: 'Rolltreppen' },
  { value: '34-5903', label: 'Röster für Nahrung' },
  { value: '26-2502', label: 'Rotationsgebläse' },
  { value: '28-4514', label: 'Rührwerke für Chemie' },
  { value: '34-5901', label: 'Rührwerke für Nahrung' },
  { value: '42-7311', label: 'Rüttelgeräte' },
  { value: '37-8951', label: 'Rüttelgeräte f. Baustoff/Steinbruch/Glas' },
  { value: '32-5559', label: 'Sägen für Holz' },
  { value: '40-6321', label: 'Sägewerke mobil' },
  { value: '39-6319', label: 'Sämaschinen' },
  { value: '30-5151', label: 'Sandaufbereitungsanl. für Gießereien' },
  { value: '40-7002', label: 'Sandgewinnungsmaschinen' },
  { value: '43-7601', label: 'Sandstrahlgeräte' },
  { value: '47-8701', label: 'Sauger (schwimmend)' },
  { value: '47-8701', label: 'Saugförderanlagen (schwimmend)' },
  { value: '28-3704', label: 'Saugfördereinricht. für Transporteinricht.' },
  { value: '41-7074', label: 'S-Bahnen' },
  { value: '33-5657', label: 'Scanner' },
  { value: '32-5559', label: 'Schälmaschinen für Holz' },
  { value: '25-2001', label: 'Schaltanlagen' },
  { value: '25-2001', label: 'Schaltwarten' },
  { value: '43-7711', label: 'Schalungsgerüste' },
  { value: '43-7711', label: 'Schalwägen' },
  { value: '42-7106', label: 'Schaufelradbagger' },
  { value: '33-5658', label: 'Scheren für graphisches Gewerbe' },
  { value: '32-5552', label: 'Scheren für Holz' },
  { value: '30-5221', label: 'Scheren für Metallverformung' },
  { value: '32-5622', label: 'Scheren zur Papierverarbeitung' },
  { value: '28-3731', label: 'Schiebebühnen' },
  { value: '35-6659', label: 'Schießstandeinrichtungen' },
  { value: '47-1551', label: 'Schiffsantriebsmotoren einschl. Getriebe' },
  { value: '47-1302', label: 'Schiffsdampfmaschinen' },
  { value: '48-7654', label: 'Schildvortriebsmaschinen im Tunnelbau' },
  { value: '46-4102', label: 'Schlägermühlen für Bergbau' },
  { value: '29-5002', label: 'Schlägermühlen für Metallaufbereitung' },
  { value: '32-5559', label: 'Schleifmaschinen für Holz' },
  { value: '31-5301', label: 'Schleifmaschinen für spanabh. Formung' },
  { value: '39-6301', label: 'Schlepper' },
  { value: '36-6921', label: 'Schlepplifte' },
  { value: '30-5160', label: 'Schmelzöfen' },
  { value: '30-5161', label: 'Schmelzöfen elektrisch beheizt' },
  { value: '30-5163', label: 'Schmelzöfen gas-, flamm- oder ind. beh.' },
  { value: '40-6402', label: 'Schneekanonen' },
  { value: '38-3401', label: 'Schneeräumgeräte' },
  { value: '33-5658', label: 'Schneidemaschinen für graph. Gewerbe' },
  { value: '34-6209', label: 'Schneidemaschinen für Nahrung' },
  { value: '33-5622', label: 'Schneidemaschinen zur Papierverarb.' },
  { value: '35-6509', label: 'Schneidmaschinen für Haus/Hotel' },
  { value: '24-1012', label: 'Schnelldampferzeuger' },
  { value: '33-5652', label: 'Schnellpressen' },
  { value: '27-3213', label: 'Schrankenanlagen' },
  { value: '42-7106', label: 'Schrapperanlagen' },
  { value: '30-5194', label: 'Schraubenpressen' },
  { value: '30-5062', label: 'Schrottpakettierpressen' },
  { value: '30-5062', label: 'Schrottscheren' },
  { value: '42-7107', label: 'Schürfgeräte in Steinbruch/Schrott' },
  { value: '44-7851', label: 'Schutzdächer' },
  { value: '43-7511', label: 'Schwarzasphaltdeckenfertiger' },
  { value: '31-5280', label: 'Schweißanlagen' },
  { value: '31-5284', label: 'Schweißautomaten' },
  { value: '31-5282', label: 'Schweißgleichrichter' },
  { value: '39-5285', label: 'Schweißgleichrichter fahrbar' },
  { value: '31-5284', label: 'Schweißroboter' },
  { value: '31-5284', label: 'Schweißstraßen' },
  { value: '31-5282', label: 'Schweißtransformatoren' },
  { value: '39-5285', label: 'Schweißtransformatoren fahrbar' },
  { value: '31-5282', label: 'Schweißumformer' },
  { value: '39-5285', label: 'Schweißumformer fahrbar' },
  { value: '35-6611', label: 'Schwimmbäder' },
  { value: '47-8701', label: 'Schwimmelevatoren' },
  { value: '47-8700', label: 'Schwimmende Sachen' },
  { value: '47-2107', label: 'Schwimmkabel' },
  { value: '47-8701', label: 'Schwimmkrane' },
  { value: '42-7106', label: 'Scrapper' },
  { value: '47-2107', label: 'Seekabel' },
  { value: '36-6921', label: 'Sessellifte' },
  { value: '29-5001', label: 'Shredder (Schrott)' },
  { value: '40-7002', label: 'Siebanlage' },
  { value: '33-5652', label: 'Siebdruckmaschinen' },
  { value: '44-7853', label: 'Signal- und Sicherungsanlagen' },
  { value: '40-7018', label: 'Silos transportabel' },
  { value: '36-6921', label: 'Skilifte' },
  { value: '36-6959', label: 'Silos für Lager/Umschlaganlagen' },
  { value: '28-3704', label: 'Slipanlagen' },
  { value: '32-5359', label: 'sonstige Anlagen der Metallbehandlung' },
  { value: '35-6819', label: 'sonstige Anl. für Film/Funk/Kino/Theater' },
  { value: '26-2519', label: 'sonstige Anlagen für Gas' },
  { value: '36-6959', label: 'sonstige Anl. für Lager/Umschlaganlag.' },
  { value: '30-5229', label: 'sonstige Anlagen für Metallverformung' },
  { value: '31-5239', label: 'sonstige Anl. für Metallwärmebehandl.' },
  { value: '25-2305', label: 'sonstige Anl. für Wasser/Abw./Pumpw.' },
  { value: '37-8969', label: 'sonstige Arbeitsmaschinen für Baustoff' },
  { value: '28-4519', label: 'sonstige Arbeitsmaschinen für Chemie' },
  { value: '33-5659', label: 'sonstige Arbeitsmasch. für graph. Gew.' },
  { value: '32-5559', label: 'sonstige Arbeitsmaschinen für Holz' },
  { value: '29-4539', label: 'sonstige Arbeitsmaschinen für Kunststoff' },
  { value: '29-4559', label: 'sonstige Arbeitsmaschinen für Leder' },
  { value: '29-5009', label: 'sonstige Arbeitsmasch. f. Metallaufber.' },
  { value: '30-5179', label: 'sonstige Arbeitsmasch. f. Metallschm.' },
  { value: '27-3229', label: 'sonstige Arbeitsmasch. f. Müll/Kompost' },
  { value: '34-6209', label: 'sonstige Arbeitsmaschinen für Nahrung' },
  { value: '31-5309', label: 'sonstige Arbeitsmasch. für span. Form.' },
  { value: '35-6729', label: 'sonstige Arbeitsmasch. für Tankst./Kfz' },
  { value: '29-4609', label: 'sonstige Arbeitsmaschinen für Textil' },
  { value: '34-5802', label: 'sonstige Arbeitsmaschinen für Zucker' },
  { value: '46-4129', label: 'sonstige Arbeitsmaschinen im Bergbau' },
  { value: '32-5619', label: 'sonstige Arbeitsmaschinen z. Papiererz.' },
  { value: '33-5629', label: 'sonstige Arbeitsmasch. z. Papierverarb.' },
  { value: '31-5269', label: 'sonstige Blechverarbeitungsmaschinen' },
  { value: '31-5269', label: 'sonstige Drahtverarbeitungsmaschinen' },
  { value: '26-2721', label: 'sonstige Einrichtungen für Kraftanlagen' },
  { value: '44-7850', label: 'sonstige Einrichtungen und Geräte/Bau' },
  { value: '44-7859', label: 'sonstige Einrichtungen und Masch. /Bau' },
  { value: '39-6319', label: 'sonstige Geräte für Landwirtschaft' },
  { value: '48-7659', label: 'sonstige Ger. für Tunnel- und Stollenbau' },
  { value: '28-3731', label: 'sonstige maschinelle Lagereinrichtungen' },
  { value: '31-5300', label: 'Spanabhebende Formung' },
  { value: '32-5502', label: 'Spann- und Hilfswagen' },
  { value: '25-2203', label: 'Spannungswandler' },
  { value: '26-2601', label: 'Speiseeisbereiter' },
  { value: '28-3704', label: 'Spillanlagen' },
  { value: '30-5194', label: 'Spindelpressen' },
  { value: '35-6600', label: 'Sportanlagen' },
  { value: '35-6659', label: 'Sporteinrichtungen' },
  { value: '38-3401', label: 'Sprengwagen' },
  { value: '29-4531', label: 'Spritzgießmaschinen' },
  { value: '30-5171', label: 'Spritzgussanlagen für Metall' },
  { value: '47-8701', label: 'Spüler (schwimmend)' },
  { value: '35-6509', label: 'Spülmaschinen' },
  { value: '41-7074', label: 'Stadtbahnen' },
  { value: '42-7311', label: 'Stampfgeräte' },
  { value: '37-8951', label: 'Stampfgeräte für Baust./Steinbruch/Glas' },
  { value: '33-5654', label: 'Stanzen für graphisches Gewerbe' },
  { value: '30-5221', label: 'Stanzen für Metallverformung' },
  { value: '29-4601', label: 'Stanzen für Textil' },
  { value: '32-5552', label: 'Stanzmaschinen für Holz' },
  { value: '33-5622', label: 'Stanzmaschinen für Papier' },
  { value: '35-6509', label: 'Staubsauger' },
  { value: '36-8901', label: 'Steinbrecher stationär' },
  { value: '40-7001', label: 'Steinbrecher transportabel' },
  { value: '36-8903', label: 'Steingatter' },
  { value: '37-8931', label: 'Steinpressen' },
  { value: '36-8903', label: 'Steinsägen' },
  { value: '34-5852', label: 'Sterilisatoren für Nahrung' },
  { value: '48-7653', label: 'Stollenbohrmaschinen im Tunnelbau' },
  { value: '31-5303', label: 'Stoßmaschinen für spanabheb. Formung' },
  { value: '30-5194', label: 'Stranggusspressen' },
  { value: '43-7500', label: 'Straßenbau' },
  { value: '41-7074', label: 'Straßenbahnen' },
  { value: '38-1522', label: 'Stromaggregate Diesel fahrbar' },
  { value: '24-1502', label: 'Stromaggregate mit Dieselmotoren' },
  { value: '24-1501', label: 'Stromaggregate mit Ottomotoren' },
  { value: '38-1521', label: 'Stromaggregate Otto fahrbar' },
  { value: '25-2203', label: 'Stromrichter' },
  { value: '25-2203', label: 'Stromwandler' },
  { value: '31-5284', label: 'Stumpfschweißmaschinen' },
  { value: '28-4519', label: 'Tablettiermaschinen' },
  { value: '26-2721', label: 'Tanks für Kraftanlagen' },
  { value: '35-6711', label: 'Tanks für Tankstellen' },
  { value: '35-6700', label: 'Tankst., Waschanl., Serv.-Stationen, Kfz-R.' },
  { value: '35-6701', label: 'Tankstelleneinrichtungen' },
  { value: '42-7106', label: 'Teleskoplader / -stapler' },
  { value: '29-4600', label: 'Textil' },
  { value: '29-4601', label: 'Textildruckmaschinen' },
  { value: '26-2601', label: 'Tiefkühltruhen' },
  { value: '33-5652', label: 'Tiegeldruckmaschinen' },
  { value: '48-4253', label: 'Torffräsen' },
  { value: '46-4259', label: 'Torfpressen' },
  { value: '46-4259', label: 'Torfverarbeitungsmaschinen' },
  { value: '44-7851', label: 'Traglufthallen' },
  { value: '39-6301', label: 'Traktoren' },
  { value: '25-2201', label: 'Transformatoren' },
  { value: '47-1601', label: 'Transmissionen in Luft- und Raumfahrt' },
  { value: '34-6329', label: 'Transportanlagen zur Landwirt. stationär' },
  { value: '40-7013', label: 'Transportbirnen' },
  { value: '28-3600', label: 'Transporteinrichtungen- Krane' },
  { value: '40-7013', label: 'Transportmischer' },
  { value: '39-6312', label: 'Traubenernter' },
  { value: '36-8903', label: 'Trennmaschinen für Steine' },
  { value: '35-6509', label: 'Trockenapparate (Haustechnik)' },
  { value: '32-5559', label: 'Trockner für Holz' },
  { value: '29-4701', label: 'Trockner für Wäsche' },
  { value: '30-5151', label: 'Trocknungsanlagen für Gießereien' },
  { value: '40-7011', label: 'Trocknungsanlagen transportabel' },
  { value: '48-7655', label: 'Tunnelaufschlitzmaschinen' },
  { value: '48-7650', label: 'Tunnelbaugeräte' },
  { value: '41-7031', label: 'Turmdrehkrane' },
  { value: '41-7074', label: 'U-Bahnen' },
  { value: '25-1803', label: 'Umformer' },
  { value: '25-2203', label: 'Umrichter' },
  { value: '35-6611', label: 'Umwälzanlagen für Schwimmbad' },
  { value: '25-2302', label: 'Unterwasserpumpen' },
  { value: '35-6509', label: 'Ventilatoren' },
  { value: '24-1500', label: 'Verbrennungskraftanlagen' },
  { value: '26-2604', label: 'Verdampfer für Kälteerzeug. und Vers.' },
  { value: '24-1501', label: 'Vergasermotore' },
  { value: '36-6910', label: 'Verkehrswesen (Personenbeförderung)' },
  { value: '38-3604', label: 'Verladebrücken' },
  { value: '39-6317', label: 'Verladeeinrichtungen für Landwirtschaft' },
  { value: '44-7853', label: 'Vermessungsgeräte' },
  { value: '33-5657', label: 'Vervielfältigungsgeräte' },
  { value: '42-7311', label: 'Vibrationsplatte' },
  { value: '47-1601', label: 'Vorgelege in Luft- und Raumfahrt' },
  { value: '29-4531', label: 'Vulkanisierpressen' },
  { value: '38-3907', label: 'Wagen' },
  { value: '41-7073', label: 'Waggons' },
  { value: '43-7321', label: 'Walzen' },
  { value: '39-6319', label: 'Walzen für Landwirtschaft' },
  { value: '31-5261', label: 'Walzmaschinen für Blechbearbeitung' },
  { value: '37-8931', label: 'Walzwerke für Baustoff/Steinbruch/Glas' },
  { value: '28-4514', label: 'Walzwerke für Chemie' },
  { value: '28-4522', label: 'Walzw. für Kunststoff/Gummi/Linoleum' },
  { value: '34-5901', label: 'Walzwerke für Nahrung' },
  { value: '30-5190', label: 'Warm- und Kaltverformung' },
  { value: '31-5230', label: 'Wärmebehandlung' },
  { value: '27-3206', label: 'Wärmepumpen' },
  { value: '24-1001', label: 'Warmwasserkessel' },
  { value: '35-6702', label: 'Waschanlagen für Kfz' },
  { value: '35-6509', label: 'Wäschezentrifugen' },
  { value: '35-6509', label: 'Waschmaschinen' },
  { value: '25-2300', label: 'Wasser- und Fernwärmeversorgung' },
  { value: '36-8904', label: 'Wasserstrahlschneideanlagen' },
  { value: '33-5621', label: 'Wellpappenmaschinen' },
  { value: '44-7851', label: 'Werkstätten/Bau' },
  { value: '40-7015', label: 'Wiegeeinrichtungen' },
  { value: '28-3704', label: 'Winden für Transporteinrichtungen' },
  { value: '41-7041', label: 'Winden transportabel' },
  { value: '46-1750', label: 'Windenergieanlagen' },
  { value: '34-5901', label: 'Wölfe für Nahrung' },
  { value: '48-7652', label: 'Wurfschaufellader im Tunnelbau' },
  { value: '35-6659', label: 'Zeitmessanlagen' },
  { value: '44-7851', label: 'Zelte' },
  { value: '41-7045', label: 'Zementpumpen einschl. Rohrleitungen' },
  { value: '34-5802', label: 'Zentrifugen für Zucker' },
  { value: '46-4102', label: 'Zerkleinerungsanlagen für Bergbau' },
  { value: '29-5002', label: 'Zerkleinerungsanl. für Metallaufbereitung' },
  { value: '34-5901', label: 'Zerkleinerungsanlagen für Nahrung' },
  { value: '46-4259', label: 'Zerkleinerungsmaschinen bei Torf' },
  { value: '28-4514', label: 'Zerkleinerungsmaschinen für Chemie' },
  { value: '32-5552', label: 'Zerkleinerungsmaschinen für Holz' },
  { value: '28-4521', label: 'Zerkleinerungsm. f Kunststoff/Gummi/L.' },
  { value: '32-5611', label: 'Zerkleinerungsmaschinen für Papier' },
  { value: '34-5800', label: 'Zuckerfabriken' },
  { value: '39-6312', label: 'Zuckerrübenvollernter' },
  { value: '41-7071', label: 'Züge (Beförderung)' },
  { value: '41-7041', label: 'Züge (Zieheinrichtung)' },
  { value: '33-5659', label: 'Zusammentragmasch. für graph. Gew.' },
  { value: '33-5652', label: 'Zylinderautomaten' },
  { value: '28-4519', label: '3D-Drucker (Chemie)' },
  { value: '29-4532', label: '3D-Drucker (Kunststoff)' },
  { value: '31-5287', label: '3D-Drucker (Metall)' },
  { value: '37-8952', label: '3D-Drucker (Bau)' },
];
